

















































import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import { AuthStoreActions, AuthStoreGetters } from '@/store/auth.store';
import User from '@/models/Dealer.model';
import { ROUTE_HOME } from '@/router/routes';
import { required, email } from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { validationMixin } from 'vuelidate';
import { mixins } from 'vue-class-component';
import { StoresStoreActions } from '@/store/stores.store';
import { ApplicationStoreMutations } from '@/store/application.store';
import Store from '@/models/Store.model';
import Dealer from '@/models/Dealer.model';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const AuthStore = namespace('auth');
const StoresStore = namespace('stores');
const ApplicationStore = namespace('application');

@Component({
  components: {
    PasswordRequestComponent: () => import(
      /* webpackChunkName: "PasswordRequestComponent" */
      '@/components/PasswordRequest.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required }
  }
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin, AxiosErrorHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.LOGIN)
  private loginAction!: (payload: { email: string, password: string }) => Promise<User>;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private currentUser?: Dealer;

  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_STORE)
  private setCurrentStore!: (store: Store) => void;

  @StoresStore.Action(StoresStoreActions.GET_BY_ID)
  private getStoreByIdAction!: (id: string) => Promise<Store>;

  private email: string = '';
  private password: string = '';

  private showPasswordDialog: boolean = false;

  private created() {
    // Redirect to home if already logged in:
    if (this.currentUser) {
      this.$router.push({ name: ROUTE_HOME });
    }
  }

  private async doLogin() {
    // Trigger validation
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        const payload = {
          email: this.email,
          password: this.password,
        };
        const user = await this.loginAction(payload);

        if (!user.isAdmin) {
          if (user.store) {
            let storeId: string;
            // Convert store id to object:
            if (typeof user.store === 'string') {
              storeId = user.store;
            } else {
              storeId = user.store.id!;
            }

            const store: Store = await this.getStoreByIdAction(storeId);
            
            this.setCurrentStore(store);
          }
        }
        await this.$router.push({ name: ROUTE_HOME });
      } catch (e) {
        this.handleAxiosError(e, () => {
          switch (e.status) {
            case 403: // Dealer not verified or not active
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_DEALER_NOT_VERIFIED_OR_ACTIVE');
              break;
            case 422: // E-Mail not registered or wrong e-mail/password
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_WRONG_CREDENTIALS');
              break;
            default:
              this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_ERROR');
          }
        });        
      }
    }
  }
}
